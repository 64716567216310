import React, { useEffect, useRef, useState } from "react";
import LoadingMessageResponse from "./LoadingMessageResponse";
import MessageResponse from "./MessageResponse";
import Response from "./Response";
import { StyledInput } from "./styled";
import "./styles.css";

const systemMessage = {
  //  Explain things like you're talking to a software professional with 5 years of experience.
  role: "system",
  content: "Act like a expert procurement advisor",
};

function SupportChat() {
  const [messages, setMessages] = useState([
    {
      message:
        "Welcome to YVOO, your AI Expert Advisor for procurement! How may I assist you today?",
      sender: "ChatGPT",
    },
  ]);
  const [message, setMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const messageEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () =>
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });

  const handleSend = async (e) => {
    e.preventDefault();
    if (!message) return;
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    // Initial system message to determine ChatGPT functionality
    // How it responds, how it talks, etc.
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    const apiRequestBody = {
      model: "gpt-4",
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT",
          },
        ]);
        setMessage("");
        setIsTyping(false);
      });
  }

  return (
    <>
      <div
        className="scrollbar-hide"
        style={{
          flex: 1,
          maxHeight: "86vh",
          overflowY: "scroll",
        }}
      >
        {messages.map((message, i) => {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
                margin: "10px 0",
              }}
              key={i}
            >
              {message.sender === "ChatGPT" ? (
                <MessageResponse message={message.message.split("\n")} />
              ) : (
                <Response message={message.message.split("\n")} />
              )}
            </div>
          );
        })}
        {isTyping && <LoadingMessageResponse />}
      </div>
      <form
        onSubmit={(e) => {
          handleSend(e);
        }}
      >
        <div style={{ width: "100%" }}>
          <StyledInput
            type="text"
            id="message"
            placeholder="Write a message..."
            disabled={isTyping}
            name="inputMessage"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{ width: "100%" }}
          />
        </div>
      </form>
    </>
  );
}

export default SupportChat;
