import React, { useEffect, useRef, useState } from "react";
// import { Configuration, OpenAIApi } from "openai";

import "./styles.css";
import LoadingMessageResponse from "./LoadingMessageResponse";
import MessageResponse from "./MessageResponse";
import Response from "./Response";
import {
  StyledAIQuestionWrapper,
  StyledCustomResponseWrapper,
  StyledFormWrapper,
  StyledInput,
  StyledQuestionWrapper,
  StyledResponseWrapper,
  StyledSendButton,
} from "./styled";
import { auth } from "stores/auth";
import { authFetch } from "functions";
import { useHistory } from "react-router-dom";

const systemMessage = {
  //  Explain things like you're talking to a software professional with 5 years of experience.
  role: "system",
  content: "Act like a ai supplier search engine",
};

const SupplierChat = ({ userEmail }) => {
  const history = useHistory();
  const [aiResponseSuppliers, setAiResponseSuppliers] = useState("");
  const [aiResponseSpecification, setAiResponseSpecification] = useState("");
  const [customResponse, setCustomResponse] = useState({
    question: "",
    response: "",
  });
  const [aiResponseError, setAiResponseError] = useState("");
  const [aiResponseQuestion, setAiResponseQuestion] = useState("");
  const [isAddedToFavorites, setIsAddedToFavorites] = useState("");
  const [disableSend, setDisableSend] = useState(false);
  const [input, setInput] = useState("");
  const [conversation, setConversation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCustom, setLoadingCustom] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [aiQuestion, setAiQuestion] = useState({
    question: "",
    showButtons: false,
  });
  const [messageSatisfied, setMessageSatisfied] = useState("");
  const [questions, setQuestions] = useState([
    {
      id: 1,
      question: `What type of product or service are you looking for? [Attributes like technical specifications or requirements, including any unique features or requirements]`,
      response: "",
      showDot: false,
    },
    {
      id: 2,
      question: "What is the intended use of the product or service?",
      response: "",
      showDot: false,
    },
    {
      id: 3,
      question:
        "In which [countries, City, Region] YVOO should search for suppliers?",
      response: "",
      showDot: false,
    },
  ]);
  const messageEndRef = useRef(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    setDisableSend(true);

    if (!input) {
      setDisableSend(false);
      return;
    }

    // update counter to update first item with no response
    let updateCounter = 0;
    let showDotId;
    const updatedQuestions = questions.map((question) => {
      if (question.response === "" && updateCounter === 0) {
        updateCounter += 1;
        showDotId = question.id + 1;
        return { ...question, response: input };
      }
      if (showDotId === question.id) {
        return { ...question, showDot: true };
      }
      return question;
    });
    setQuestions(updatedQuestions);
    setTimeout(() => {
      updateItem(showDotId, updatedQuestions);
    }, 1000);
    setInput("");
    if (updatedQuestions[updatedQuestions.length - 1].response) {
      setAiQuestion({
        ...aiQuestion,
        question: `Thank you for answering all the questions, I will start your search now.`,
      });

      const specificationContent = `Please write me a Technical Specification for ${updatedQuestions[0].response}: Create a detailed technical specification ${updatedQuestions[0].response}. Include key features and requirements, and compliance with applicable standards for ${updatedQuestions[1].response}`;
      const suppliersContent = `Now please list me 20 reputable suppliers. Please do not advise me to use a general approach on how to find them. Please give me just the names of suppliers devided by new line without any numbers or additional text or anything. Suppliers should be only from ${updatedQuestions[2].response}`;
      const specification = await getChatAIResponse(specificationContent);
      let suppliers = "";
      if (specification) {
        suppliers = await getChatAIResponse(
          suppliersContent,
          specificationContent,
          specification
        );
      }

      setAiResponseSpecification(specification);
      setAiResponseSuppliers(suppliers);
      setShowDetails(true);
      setAiResponseQuestion(
        "Please note that this information is based on publicly available sources and may not be up-to-date or accurate. It's always recommended to do use our YVOO services helping do more research and the verification of those suppliers before to make a sourcing decision."
      );
      setLoading(false);
    }
    setDisableSend(false);
  };

  const updateItem = (id, updatedQuestions) => {
    const newQuestions = updatedQuestions.map((question) => {
      if (id === question.id) {
        return { ...question, showDot: false };
      }
      return question;
    });
    setQuestions(newQuestions);
  };

  useEffect(() => {
    scrollToBottom();
  }, [
    questions,
    aiResponseSuppliers,
    aiResponseSpecification,
    isAddedToFavorites,
  ]);

  const scrollToBottom = () =>
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });

  const getChatAIResponse = async (
    content,
    specificationContent,
    specification
  ) => {
    let messages = [];
    try {
      setLoading(true);
      if ((specificationContent, specification)) {
        messages = [
          systemMessage,
          {
            role: "user",
            content: specificationContent,
          },
          {
            role: "assistant",
            content: specification,
          },
          {
            role: "user",
            content: content,
          },
        ];
      } else
        messages = [
          systemMessage,
          {
            role: "user",
            content: content,
          },
        ];
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            model: "gpt-4",
            messages: messages,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (
        data &&
        data.choices &&
        data.choices[0] &&
        data.choices[0].message &&
        data.choices[0].message.content
      ) {
        return data.choices[0].message.content;
      } else {
        throw new Error("Invalid data received from the server");
      }
    } catch (error) {
      setAiResponseError(
        "Sorry, our AI is having some problems. Try again later.\n"
      );
      return null; // or handle the error as needed
    }
  };

  const handleSaveConversation = async (e) => {
    e.preventDefault();
    setShowDetails(false);

    const suppliersArray = aiResponseSuppliers
      .split("\n")
      .filter((str) => str.trim() !== "");

    if (suppliersArray.length === 0) return;

    const formData = {
      dateCreated: new Date().toISOString(),
      product: questions[0].response,
      productUse: questions[1].response,
      location: questions[2].response,
      productSpecification: aiResponseSpecification,
      suppliers: suppliersArray,
      userEmail: userEmail,
      type: "supplier",
    };
    authFetch(`${process.env.REACT_APP_MSG_ENDPOINT}/api/messages`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + auth.user.signInUserSession.idToken.jwtToken,
      },
      body: JSON.stringify(formData),
    })
      .then(async (response) => {
        const data = await response.json();
        if (!data) return;
        setIsAddedToFavorites("Successfully added to AI search history");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      <div
        className="scrollbar-hide"
        style={{
          flex: 1,
          maxHeight: "80vh",
          overflowY: "scroll",
        }}
      >
        <MessageResponse
          message={["Thanks for choosing supplier search, so let’s start!"]}
        />
        {questions.map((question, i) => {
          return (
            <StyledQuestionWrapper key={i}>
              {question.showDot && <LoadingMessageResponse />}
              {(i === 0 || questions[i - 1]?.response) && !question.showDot && (
                <MessageResponse message={question.question.split("\n")} />
              )}
              {question.response && (
                <Response message={question.response.split("\n")} />
              )}
            </StyledQuestionWrapper>
          );
        })}
        {aiQuestion.question && (
          <StyledAIQuestionWrapper>
            <MessageResponse message={aiQuestion.question.split("\n")} />
          </StyledAIQuestionWrapper>
        )}
        <StyledResponseWrapper>
          {loading ? (
            <LoadingMessageResponse />
          ) : (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              {aiResponseSpecification && (
                <MessageResponse
                  message={aiResponseSpecification.split("\n")}
                />
              )}
              {aiResponseSuppliers && (
                <MessageResponse message={aiResponseSuppliers.split("\n")} />
              )}
            </div>
          )}
        </StyledResponseWrapper>
        {aiResponseQuestion && (
          <StyledResponseWrapper style={{ color: "red" }}>
            <MessageResponse message={aiResponseQuestion.split("\n")} />
          </StyledResponseWrapper>
        )}

        {isAddedToFavorites && (
          <>
            <StyledResponseWrapper style={{ color: "red" }}>
              <MessageResponse message={isAddedToFavorites.split("\n")} />
            </StyledResponseWrapper>
            <StyledSendButton
              className="button"
              variant="primary"
              size="lg"
              type="submit"
              onClick={() => history.push(`/ai-suppliers`)}
              style={{ width: "100%" }}
            >
              Go to History AI search
            </StyledSendButton>
          </>
        )}

        <StyledCustomResponseWrapper>
          {customResponse.question && (
            <Response
              message={
                customResponse.question
                  ? customResponse.question.split("\n")
                  : [""]
              }
            />
          )}
          {loadingCustom ? (
            <LoadingMessageResponse />
          ) : (
            <>
              {customResponse.response && (
                <MessageResponse
                  message={customResponse.response.split("\n")}
                />
              )}
            </>
          )}
        </StyledCustomResponseWrapper>
        {messageSatisfied && (
          <StyledResponseWrapper>
            <MessageResponse message={messageSatisfied.split("\n")} />
          </StyledResponseWrapper>
        )}
        <StyledResponseWrapper>
          {aiResponseError && (
            <MessageResponse message={aiResponseError.split("\n")} />
          )}
        </StyledResponseWrapper>

        <div ref={messageEndRef} />
      </div>
      <form
        onSubmit={
          aiResponseSpecification && aiResponseSuppliers && showDetails
            ? handleSaveConversation
            : onSubmit
        }
      >
        <StyledFormWrapper>
          <StyledInput
            type="text"
            placeholder="Write a message..."
            disabled={
              aiResponseSuppliers || disableSend || loading || loadingCustom
            }
            name="inputMessage"
            value={input}
            onChange={(e) => setInput(e.target.value)}
          />

          {aiResponseSpecification && aiResponseSuppliers && showDetails ? (
            <StyledSendButton
              className="button"
              variant="primary"
              size="lg"
              type="submit"
              style={{ backgroundColor: "#06d7f9", color: "white" }}
            >
              ADD TO MY SUPPLIERS
            </StyledSendButton>
          ) : (
            <StyledSendButton
              className="button"
              disabled={
                (aiResponseSpecification && aiResponseSuppliers) ||
                disableSend ||
                loading ||
                loadingCustom
              }
              variant="primary"
              size="lg"
              type="submit"
            >
              {loading ? "Loading..." : "send"}
            </StyledSendButton>
          )}
        </StyledFormWrapper>
      </form>
    </>
  );
};

export default SupplierChat;
